@import "~bootstrap/scss/_functions";

//Bootstrap Replacement
$primary: #2487BF;
$secondary: #D0EEFF;
$success: #39D4A6;
$danger: #F0584D;
$warning: #FFD940;
$light: #F0F6FC;
$dark: #1F2F3F;
$dark-gray: #677888;
$muted: #8A949F;
$text-muted: #8A949F;
$orange: #FBB03F;
$blue: #27a7dc;
$white: #FFFFFF;
$purple: #AA24BF;

//Custom
$yellow-green: #9CD673;
$gray: #9AA9B8;
$gray-border: #ced4da;
$mid-light-gray: #D9D9D9;
$light-gray: #DCE8F3;
$google-btn-color: #dd4b39;
$microsoft-btn-color: #6264A7;
$mediumseagreen: #2AB27B;
$white-transparent: rgba(255, 255, 255, 0.5);
$darken-btn-secondary-text: #212529;
$darken-btn-secondary-background: #9ddcff;
$darken-btn-light-background: #c6ddf4;
$assigned-to-color: #91c3df;
$forecasted-column-color: rgba(68, 170, 213, .2);
$pill-orange: #F5A262;

//Variable color matching
$header: $dark-gray;
$body-background: $light;
$input-placeholder-color: $gray;
$dropdown-link-hover-bg: $light;

//Table column background colors
$success-green: #E1F9F2;
$success-yellow: #FFF7D9;
$success-red: #FDE6E4;

.bg-success-green {
  background-color: $success-green !important;
}

.bg-success-yellow {
  background-color: $success-yellow !important;
}

.bg-success-red {
  background-color: $success-red !important;
}

.text-primary {
  color: $primary !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-forecast-column {
  background-color: $forecasted-column-color !important;
  fill: $forecasted-column-color !important;
}

.text-success {
  color: $success !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-success--lighten {
  background-color: tint-color($success, 85%) !important;
}

.text-danger {
  color: $danger !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-danger--lighten {
  background-color: tint-color($danger, 80%) !important;
}

.text-warning {
  color: $warning !important;
}

.bg-warning {
  background-color: $warning !important;
}

.text-light {
  color: $light !important;
}

.bg-light {
  background-color: $light !important;
}

.border-light {
  border: 1px solid $light !important;
}

.border-light-gray {
  border: 1px solid $light-gray !important;
}

.text-light-gray {
  color: $light-gray !important;
}

.bg-light-gray {
  background-color: $light-gray !important;

  &:hover {
    background-color: $gray;
  }
}

.text-dark {
  color: $dark !important;
}

.bg-dark {
  background-color: $dark !important;
}

.bg-muted {
  background-color: $muted !important;
}

.text-black {
  color: black !important;
}

.bg-black {
  background-color: black !important;
}

.text-orange {
  color: $orange !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-unset {
  background-color: unset !important;
}

.text-gray {
  color: $gray !important;
}

.text-dark-gray {
  color: $dark-gray !important;
}

.text-purple {
  color: $purple !important;
}

.bg-purple {
  background-color: $purple !important;
}

.bg-gray {
  background-color: $gray !important;
}

.text-assigned-to {
  color: $assigned-to-color;
}

.bg-transperent {
  background-color: transparent !important;
}

.text-none {
  color: transparent !important;
}
