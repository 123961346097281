@import 'mixins';

.decision-tile {
  max-width: 300px;
}

.logo-dropdown {
  img {
    height: 40px;
  }
}

.search-decisions {
  min-width: 320px;
}

.col-insight-data {
  min-width: 400px;
}

.home.container.learn-container {
  margin-left: auto;
  margin-right: auto;

  .homepage_tile {
    width: 330px;
    max-width: unset;
    min-width: 330px;
  }
}
.scroll-margin-tab {
  scroll-margin-top: 120px;
}

.search-row {
  .homepage_tile {
    padding-left: 4px;
    width: auto;
    max-width: unset;
    min-width: unset;
  }
}

.search-scenario-block {
  display: flex;
  .search-row {
    margin-top: 0;
    margin-left: 0.75rem;
  }
}

.homepage_tile, .homepage-list-col {
  .collaborators,
  .bubblesRow {
    height: 36px;
    overflow: hidden;

    .rounded-circle {
      height: 36px;
      width: 36px;
      border: thin solid white;
      
      .rounded-circle {
        margin: -1px;
        cursor: default;
      }

      &:not(:first-child) {
        margin-left: -5px;
      }
    }

    .rectangle-space {
      height: 36px;
      width: 36px;
    }
  }

  .tile-content, .list-col-content {
    border-radius: $btn-border-radius;
    overflow: hidden;
  }
}

.homepage-list-col {
  .collaborators {
    width: 153px;
  }
}

.homepage_tile {
  width: 320px;
  max-width: 320px;
  min-width: 320px;

  .tile-content {
    max-height: 210px;

    .mb-3 {
      margin-bottom: 20px !important;
    }

    .mt-unstarted-pill {
      margin-top: 66px;
    }

    &.set-folder {
      border-top-left-radius: 0;
      border-top: 1px solid $gray;

      &:before {
        content: '';
        background-image: url("../../../../assets/images/folder_tab_220x26.png");
        background-size: 110px 13px;
        background-position: left;
        background-repeat: no-repeat;
        position:absolute;
        top:-12px;
        left:12px;
        width: 110px;
        height: 13px;
      }
    }

    &.template-tile-height {
      height: 161px;
      padding-top: 46px;
    }
  }

  .h3,
  .h4 {
    font-weight: 400;
  }

  h3:not(:first-child) {
    margin-top: 1.5rem;
  }

  .decision-title {
    height: 72px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    h4 {
      margin-bottom: 11px !important;
    }
  }

  .bubblesRow {
    position: absolute;
    top: -12px;
  }

  .btn {
    min-height: 36px;
  }

  .assignedSubTitleRow {
    height: 36px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.badge {
  line-height: inherit;
}

.rounded-pill {
  padding: 6px;
  color: $white;

  &.badge-pill-char {
    background-color: $muted;
    height: 24px;
    line-height: 24px;
    margin-left: auto;
    margin-top: -24px;
    padding: 0 12px;
    position:relative;
    top: 12px;
    width: min-content;

    &.comments {
      background-color: $primary;
      padding: 0 7px !important;
      height: 20px !important;
      min-width: 24px;
      line-height: 1.3rem !important;
      text-align: center;
    }

    &.driver-badge-pill {
      z-index: 1;
      width: max-content;
      &.warning {
        background-color: $pill-orange;
      }
    }
  }
}

.sidepanel-minwidth {
  min-width: 296px;
}

.semaphore-badge {
  font-size: 0.875rem;
  padding: 5px;
  position: absolute;
  top: -10px;
  min-width: 242px;
}

.levers-height {
  height: calc(100% - 60px)
}

.home.container {
  max-width: 630px;
  margin-left: initial;
  margin-right: auto;

  @include pagination()
}

.typehead-hide-match {
  .dropdown-menu.show {
    display: none !important;
  }
}

.dropdown-menu.org-dropdown {
  max-height: 80vh;
  overflow: auto;
}

.search-input {
  padding-right: 60px;
}

.form-control.rounded {
  border-radius: 10px !important;
}

.wrapper-container {
  padding: 0 1rem;
}

.rotate-svg-0 {
  svg {
    transition-duration: 0.5s;
    transform: rotate(0deg)
  }
}
.rotate-svg-180 {
  svg {
    transition-duration: 0.5s;
    transform: rotate(180deg)
  }
}

.rotate-after-180 {
  &:after {
    transform: rotate(225deg)
  }
}

.accordion-arrow {
  margin-top: 3px !important;
  &.rotated {
    margin-top: 8px !important;
  }
}

.statistic-section-max-h {
  max-height: 460px;
}

.answers-table-max-h {
  max-height: 360px;
}

.w-0 {
  width: 0;
}

@media only screen and (max-width: 484px) {
  .width-fit-content {
    min-width: fit-content;
  }
}

@media only screen and (min-width: 768px) {
  .home.container {
    max-width: 990px;

    &.learn-container {
      .homepage_tile {
        max-width: 330px;
      }
    }
  }

  .wrapper-container {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 991px) {
  #sidebar-navigation {
    .dropdown-toggle {
      &:after{
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .home.container {
    max-width: 330px;
  }
}
@media only screen and (max-width: 1199px) {
  .search-row {
    .homepage_tile {
      &.default-tab {
        padding-left: 12px;
        margin-top: 0.75rem;
      }
    }
  }
}
@media only screen and (max-width: 1308px) {
  .home.container {
    max-width: 640px;
  }
  .search-row {
    .homepage_tile {
      &.search-tab {
        padding-left: 12px;
        width: 100%;
        margin-top: 0.75rem;
      }
    }
  }
  .search-scenario-block {
    display: block;
    .search-row {
      margin-top: 0.75rem;
      margin-left: 0;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .search-row {
    .homepage_tile {
      &.default-tab {
        margin-left: auto;
        margin-top: 0;
      }
    }
  }
}

@media only screen and (min-width: 1201px) {
  .product-forecast-container {
    max-width: 1200px;
  }

  .launch-container {
    max-width: 1176px;
  }
}

@media only screen and (min-width: 1309px) {
  .home.container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1614px) {
  .home.container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
  .search-row {
    .homepage_tile {
      &.search-tab {
        margin-left: auto;
      }
    }
  }
}

@media only screen and (min-width: 1764px) {
  .wrapper-container {
    padding: 0 calc(135px + 2rem) 0 2rem;
  }
}
